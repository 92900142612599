import Box from "./Box";
import PropTypes from "prop-types";
import styled from "styled-components";

const EmbedBlockInner = styled.div`
  position: relative;
  height: 100%;
  &::after {
    content: "";
    display: block;
    padding-bottom: 56.25%;
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

function EmbedBlock({ value: { html } }) {
  return (
    <Box position="relative" height="100%">
      <EmbedBlockInner dangerouslySetInnerHTML={{ __html: html }} />
    </Box>
  );
}

EmbedBlock.propTypes = {
  value: PropTypes.shape({
    url: PropTypes.shape({
      html: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  }),
};

export default EmbedBlock;
