import gql from "graphql-tag";

export const GET_GLOBAL_SITE_SETTINGS = gql`
  query GetGlobalSiteSettings {
    globalSiteSettings {
      enquiriesEmail
      investmentsEmail
      corporateEmail
      corporatePhone
      megawatts
      videoId
    }
  }
`;

export const GET_TICKER_DATA = gql`
  query GetTickerData {
    globalSiteSettings {
      tickerItemEn
      tickerItemDe
      tickerItemFr
      tickerItemJa
      tickerShow
      tickerUrl
    }
  }
`;

export const GET_LEGAL_PAGES = gql`
  query GetLegalPages($locale: String!) {
    legalPages(locale: $locale) {
      title
      slug
    }
  }
`;

export const GET_LEGAL_PAGE = gql`
  query GetLegalPage($slug: String!, $locale: String!) {
    legalPage(slug: $slug, locale: $locale) {
      title
      content
    }
  }
`;

export const GET_LEGAL_PAGE_PREVIEW = gql`
  query GetLegalPagePreview($token: String!) {
    legalPage(token: $token) {
      title
      content
    }
  }
`;
